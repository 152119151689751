// src/pages/Home.jsx
import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import Sidebar from '../components/Sidebar';
import { Navigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
// import { setSelectedVillage } from '../store/villageSlice';
import "../css/Home.css" 

const countryData = {
  India: {
    states: {
      Telangana: {
        districts: {
          Komarambheen: [
           "Wankidi","Dongargaon",
          ],
        },
      },
      Maharastra: {
        districts: {
          Chandrapur: ["Chincholi","Pudumkappi",],
        },
      },
    },
  },
};

// Popup Component
function Popup({ onClose }) {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <h2 className='font-bold text-red-800'>Disclaimer</h2><br/>
        <p className='popup-message'>The data presented
                  on this website has been collected from various sources and
                  may include outdated, incomplete, or inaccurate information.
                  It is intended for general reference purposes only . <br/><br/>  
                  Christ Focus Mission does not guarantee the accuracy or completeness
                  of the data and is not responsible for any decisions or
                  actions taken based on this information. Use caution and
                  consult additional sources when making decisions.</p>
        <button onClick={onClose} className='popup-button'>OK</button>
      </div>
    </div>
  );
}

function Home() {
  const defaultCountry = "India"; // Default country
  const [countries] = useState(Object.keys(countryData));
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [villages, setVillages] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(localStorage.getItem('selectedCountry') || defaultCountry);//defaultCountry
  const [selectedState, setSelectedState] = useState(localStorage.getItem('selectedState') || "");
  const [selectedDistrict, setSelectedDistrict] = useState(localStorage.getItem('selectedDistrict')|| "");
  const [selectedVillage, setSelectedVillageState] = useState(localStorage.getItem('selectedVillage') || ""); // Renamed to avoid conflict
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  


  useEffect(() => {
    // Show popup only once per login session
    const popupShown = sessionStorage.getItem('popupShown');
    if (!popupShown) {
      setShowPopup(true);
      sessionStorage.setItem('popupShown', 'true');
    }
  }, []);


// localstorage
  useEffect(() => {
    if (selectedCountry && countryData[selectedCountry]) {
      setStates(Object.keys(countryData[selectedCountry].states));
    }
    if (selectedState && countryData[selectedCountry]?.states[selectedState]) {
      setDistricts(Object.keys(countryData[selectedCountry].states[selectedState].districts));
    }
    if (selectedDistrict && countryData[selectedCountry]?.states[selectedState]?.districts[selectedDistrict]) {
      setVillages(countryData[selectedCountry].states[selectedState].districts[selectedDistrict]);
    }
  }, [selectedCountry, selectedState, selectedDistrict]);





// country
  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    localStorage.setItem('selectedCountry', country);//add


    if (countryData[country]) {
      setStates(Object.keys(countryData[country].states));
    } else {
      setStates([]);
    }
    setDistricts([]);
    setVillages([]);
    setSelectedState('');
    setSelectedDistrict('');
    setSelectedVillageState(''); // Update state
  };
// state
  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    localStorage.setItem('selectedState',state)

    if (countryData[selectedCountry]?.states[state]) {
      setDistricts(Object.keys(countryData[selectedCountry].states[state].districts));
    } else {
      setDistricts([]);
    }
    setVillages([]);
    setSelectedDistrict('');
    setSelectedVillageState(''); // Update state
  };




  // district
  const handleDistrictChange = (e) => {
    const district = e.target.value;
    setSelectedDistrict(district);
    localStorage.setItem('selectedDistrict',district);

    if (countryData[selectedCountry]?.states[selectedState]?.districts[district]) {
      setVillages(countryData[selectedCountry].states[selectedState].districts[district]);
    } else {
      setVillages([]);
    }
    setSelectedVillageState(''); // Update state

  };

// village

  const handleVillageChange = (e) => {
    const village = e.target.value;
    setSelectedVillageState(village); // Update state
    localStorage.setItem('selectedVillage', village);
    // dispatch(setSelectedVillage(village)); // Dispatch Redux action
    navigate(``);
  };



  //page clear
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    return <Navigate to="/" />;
  }

// Close popup 
const closePopup = () => {
  setShowPopup(false);
};

  return (
    <>
      {showPopup && 
        <Popup
          onClose={closePopup}
        />}
      <div className="flex blureffect">
        <Sidebar
          selectedCountry={selectedCountry} //add
          selectedVillage={selectedVillage}
          selectedState={selectedState}
          selectedDistrict={selectedDistrict}
        />
        <div className="flex-1 ">
          <div className="h-12 rounded-md shadow-md px-4 flex justify-left items-left">
            <div>
              <h1 className="text-lg font-semibold text-[#5e5c8c] mt-2">
                Home
              </h1>
            </div>
            <div
              class="bg-[url('./image/cfm6.svg')] top-0 right-1  h-0 w-0 bg-no-repeat justify-center absolute"
              style={{ height: "40px", width: "40px", marginTop: "6px" }}
            ></div>
          </div>
          <div className="image bg-full bg-no-repeat justify-items-center bg-fixed bg-cover">
            <div className="glass">
              <div className="flex justify-center items-center">
                <h1
                  className="text-2xl block items-center font-extrabold"
                  style={{
                    marginBottom: "1vw",
                    marginTop: "1vw",
                    color: "#405cb8",
                  }}
                >
                  {" "}
                  {/* <div className="text-center mx-auto text-3xl font-bold"
                style={{
                  marginBottom: "1vw",
                  marginTop: "1vw",
                  color: "#64c4c4",
                }}
                > */}
                  Data For Christ
                  <sup
                    className=""
                    style={{
                      fontSize: "8px",
                      verticalAlign: "super",
                      paddingLeft: "1px",
                    }}
                  >
                    TM
                  </sup>
                </h1>
              </div>
              {/* <div className="scrolling-container"> */}

              {/* </div> */}
              <div className="flex justify-center  items-center">
                <div
                  className=" log_bg w-96 p-5 pb-8 px-6 py-5 shadow-2xl bg-white bg-opacity-60 rounded-md"
                  style={{ marginTop: "1vw" }}
                >
                  <div className="">
                    <h2
                      className="text-2xl block text-center text-gray-500"
                      // style={{ color: "grey" }}
                    >
                      Fetch Data
                    </h2>
                    <div>
                      <form className="mt-4 space-y-6">
                        <div>
                          <label
                            htmlFor="country"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            Country
                          </label>
                          <select
                            id="country"
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            className={`w-40 pl-2 px-4 py-2 text-base ml-3 border border-gray-300 text-gray-500 font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedCountry
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            style={{
                              color: selectedCountry ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select Country</option>
                            {countries.map((country) => (
                              <option key={country} value={country}>
                                {country}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div>
                          <label
                            htmlFor="state"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            State
                          </label>
                          <select
                            id="state"
                            value={selectedState}
                            onChange={handleStateChange}
                            className={`w-40 pl-2 px-4 py-2 ml-7 text-base border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedState
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedCountry}
                            style={{
                              color: selectedState ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select State</option>
                            {states.map((state) => (
                              <option key={state} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="district"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            District
                          </label>
                          <select
                            id="district"
                            value={selectedDistrict}
                            onChange={handleDistrictChange}
                            className={`w-40 pl-2 px-4 py-2 ml-3 text-base border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedDistrict
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedState}
                            style={{
                              color: selectedDistrict ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select District</option>
                            {districts.map((district) => (
                              <option key={district} value={district}>
                                {district}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label
                            htmlFor="village"
                            className="text-sm font-extrabold text-gray-500 ml-16"
                          >
                            Village
                          </label>
                          <select
                            id="village"
                            value={selectedVillage}
                            onChange={handleVillageChange}
                            className={`w-40 pl-2 px-4 py-2 text-base ml-3 border border-gray-300  font-semibold focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md ${
                              selectedVillage
                                ? "border-gray-300"
                                : "border-red-500"
                            }`}
                            disabled={!selectedDistrict}
                            style={{
                              color: selectedVillage ? "#293f91" : "gray",
                            }}
                          >
                            <option value="">Select Village</option>
                            {villages.map((village) => (
                              <option key={village} value={village}>
                                {village}
                              </option>
                            ))}
                          </select>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
